<template lang="">
  <v-container 
    id="body-container-card-fvl"
    v-if="
      userSession.permissions.indexOf(permissionsAll.user_list) >= 0 ||
        userSession.person.types_person.id === 3
    "
  >
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <LoaderLinear />
    <div id="fvl-container-card-table">
    <v-card elevation="0"  class="fvl-card-searcher-table">
      <v-tabs class="mb-6">
        <v-tab @click="changeActive(true)">Activos</v-tab>
        <v-tab @click="changeActive(false)">Inactivos</v-tab>
      </v-tabs>
      <div class="d-flex justify-center">
        <v-text-field
          v-model="search"
          class="mb-6 searcher-input-fvl elevation-1"
          label="Buscador de usuarios"
          append-icon="search"
          dense
          filled
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        :headers="dataTableheaders"
        :items="dataTableItems"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="seeItem(item)"
            v-if="
              userSession.permissions.indexOf(permissionsAll.user_list) >= 0 ||
                userSession.person.types_person.id === 3
            "
          >
            visibility
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="
              item.state == 2 &&
                (userSession.permissions.indexOf(permissionsAll.user_update) >= 0 ||
                  userSession.person.types_person.id === 3)
            "
          >
            edit
          </v-icon>
          <v-icon
            small
            @click="clickItem(item, 1)"
            v-if="
              item.state == 2 &&
                (userSession.permissions.indexOf(permissionsAll.user_delete) >= 0 ||
                  userSession.person.types_person.id === 3)
            "
          >
            delete
          </v-icon>
          <v-icon
            small
            @click="clickItem(item, 2)"
            v-if="
              item.state == 1 &&
                (userSession.permissions.indexOf(permissionsAll.user_update) >= 0 ||
                  userSession.person.types_person.id === 3)
            "
          >
            person_add_disabled
          </v-icon>
        </template>
      </v-data-table>
   
    </v-card>
    </div>
    <SnackBar />
    <ButtonAdd
      :link="linkAdd"
      v-if="
        userSession.permissions.indexOf(permissionsAll.user_create) >= 0 ||
          userSession.person.types_person.id === 3
      "
    />
    <DialogConfirm @action="changeStateUser" />
    <Overlay />
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import permissions from "../../helpers/permissions";
import router from "../../router";
import {
  links,
  itemsBreadCrumbs,
  beginRequest,
  endRequest,
} from "../../helpers";
import {
  LoaderLinear,
  SnackBar,
  Overlay,
  DialogConfirm,
  BreadCrumbs,
  ButtonAdd,
} from "../../components/_commons";

export default {
  name: "UserView",
  components: {
    BreadCrumbs,
    ButtonAdd,
    LoaderLinear,
    DialogConfirm,
    SnackBar,
    Overlay,
  },
  data() {
    return {
      activeUserOnly: true,
      linkAdd: links.user.create,
      itemsBreadCrumbs: itemsBreadCrumbs.userList,
      search: "",
      dataTableheaders: [
        {
          text: "Nombre, Apellido o Razón Social",
          align: "start",
          value: "get_person.name",
          class:"file-name-table"
        },
        { text: "Correo Electrónico", value: "email", class:"file-name-table"},
        { text: "Rol", value: "get_person.get_type_person.description", class:"file-name-table" },
        { text: "Acciones", value: "actions", class:"file-name-table", sortable: false },
      ],
      dataTableItems: [],
      url: "",
      actionDialog: "",
      newState: "",
      permissionsAll: permissions,
    };
  },
  methods: {
    async changeStateUser() {
      await beginRequest();
      await this.$store.dispatch("setDialogShowAction");
      const { snack, status } = await this.$store.dispatch(
        "changeStateUserAction",
        {
          url: this.url,
          id: this.ui.idItemDialog,
          newState: this.newState,
        }
      );
      this.generateTable();
      await endRequest(snack, status);
    },
    generateTable() {
      if (this.activeUserOnly) {
        this.dataTableItems = this.getActiveUsers;
      } else {
        this.dataTableItems = this.getInactiveUsers;
      }
    },
    changeActive(value) {
      this.activeUserOnly = value;
      this.generateTable();
    },
    editItem(item) {
      this.$store.dispatch("removeUserSelectedAction");
      router.push(`${links.user.update}/${item.id}`);
    },
    seeItem(item) {
      this.$store.dispatch("removeUserSelectedAction");
      router.push(`${links.user.profile}/${item.id}`);
    },
    async clickItem(item, newState) {
      this.newState = newState;
      const msg =
        newState == 2
          ? `¿Esta seguro que desea activar al usuario "${item.get_person.name}"?`
          : `¿Esta seguro que desea eliminar al usuario "${item.get_person.name}"?`;

      this.url = newState == 2 ? "activeUser" : "deleteUser";
      await this.$store.dispatch("setIdItemDialogAction", item.id);
      await this.$store.dispatch("setDialogShowAction");
    },
  },
  computed: {
    ...mapState({
      ui: (state) => state.ui,
      users: (state) => state.users,
      userSession: (state) => state.user,
    }),
    ...mapGetters([
      "getActiveUsers",
      "getInactiveUsers",
      "getActiveUsersWithoutSession",
    ]),
  },
  async created() {
    await beginRequest();
    const { snack, status } = await this.$store.dispatch("getAllUsersAction");
    this.generateTable();
    await endRequest(snack, status);
  },
};
</script>
